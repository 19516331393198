import { graphql, useStaticQuery } from 'gatsby';
import { reverse } from 'ramda';
import React from 'react';
/** @jsx jsx */
import { Box, Flex, Grid, Heading, Text, jsx } from 'theme-ui';
import slugify from 'underscore.string/slugify';

import { FoodMenuNav, SEO } from 'components';

const WINE_QUERY = graphql`
  query {
    wines: allContentfulWineVarietal {
      edges {
        node {
          ...WineFragment
        }
      }
    }
  }
`;

function WineMenuSection(props) {
  const { menu } = props;

  return (
    <Box
      sx={{
        maxWidth: 780,
        px: ['m', null, 'l', 'xl'],
        pt: ['l', 'xl', 'xxl'],
        boxSizing: 'content-box',
      }}
      id={slugify(menu.name)}
    >
      <Box>
        <Heading variant="heading.l" sx={{ mb: ['s'] }}>
          {menu.name}
        </Heading>

        <Grid
          columns="1fr auto"
          mb="s"
          sx={{
            position: 'sticky',
            top: 0,
            left: 0,
            bg: 'white.100',
            py: ['s'],
          }}
        >
          <Box sx={{ display: ['none', null, 'block'] }} />
          <Grid
            columns="repeat(3, 80px)"
            gap="s"
            sx={{ textAlign: [null, null, 'right'], color: 'black.40' }}
          >
            <Text variant="stnd.sub">Regular Glass</Text>
            <Text variant="stnd.sub">Large Glass</Text>
            <Text variant="stnd.sub">Bottle</Text>
          </Grid>
        </Grid>

        <Grid gap={['l', null, 'l']}>
          {menu.wines.map((wine) => (
            <Grid columns={[null, null, '1fr auto']} gap={['m', null, 'l']}>
              <Box>
                <Flex
                  sx={{
                    flexDirection: ['column', null, 'row'],
                    alignItems: [null, null, 'center'],
                    mb: 's',
                  }}
                >
                  <Heading variant="heading.s" sx={{ color: 'orange.dark' }}>
                    {wine.name}
                  </Heading>
                  <Text ml={[null, null, 's']} variant="stnd.xs">
                    {wine.location}
                  </Text>
                </Flex>
                {wine?.description?.description && (
                  <Text sx={{ color: 'black.60', fontSize: 'sub' }}>
                    {wine.description?.description}
                  </Text>
                )}
              </Box>

              <MobileWineTypes wine={wine} />

              <Grid
                columns="repeat(3, 80px)"
                gap="s"
                sx={{
                  textAlign: [null, null, 'right'],
                  display: ['none', null, 'grid'],
                }}
              >
                <Box>{wine?.regularGlass}</Box>
                <Box>{wine?.largeGlass}</Box>
                <Box>{wine?.bottle}</Box>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

function MobileWineTypes(props) {
  const { wine } = props;
  const { regularGlass, largeGlass, bottle } = wine;
  return (
    <Grid
      columns="repeat(3, 1fr)"
      gap="s"
      sx={{
        textAlign: [null, null, 'right'],
        display: [null, null, 'none'],
        fontSize: 'sub',
      }}
    >
      {regularGlass && <Box>Regular: {regularGlass}</Box>}
      {largeGlass && <Box>Large: {largeGlass}</Box>}
      {bottle && <Box>Bottle: {bottle}</Box>}
    </Grid>
  );
}

function WineMenu() {
  const { wines } = useStaticQuery(WINE_QUERY);

  const options = reverse(wines.edges);

  return (
    <>
      <SEO title="Wine Menu" />
      <Box variant="boxes.container" pb={['xl', 'xxl', 'xxxl']}>
        <Grid columns={[null, null, '300px auto', '370px auto']}>
          <FoodMenuNav options={options} />

          <Box>
            {options.map((option) => (
              <WineMenuSection menu={option.node} />
            ))}
          </Box>
        </Grid>
      </Box>
    </>
  );
}

export const WineFragment = graphql`
  fragment WineFragment on ContentfulWineVarietal {
    name
    wines {
      name
      location
      largeGlass
      regularGlass
      bottle

      description {
        description
      }
    }
  }
`;

export default WineMenu;
